import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITheme } from 'models';

import ActiveThemeCard from 'components/admin2/Themes/ActiveThemeCard';
import TranslatedText from 'components/i18n/TranslatedText';
import Input from 'components/admin2/TextInput';

import {
  createAccentColors,
  createHighlightColors,
  createSurfaceColors,
  getForkedTheme,
  setForkedTheme,
  createTextColors,
} from 'services/themes';
import { setPendingThemesDoc, setDraftTheme } from 'services/admin/actions';
import { getPendingThemesDoc } from 'services/admin/selectors';

import useFonts, { IFonts } from 'hooks/use-fonts';
import { StyledTextBlock } from '../styles';
import {
  DesktopBackgroundUploadZone,
  Divider,
  MobileBackgroundUploadZone,
  StyledContentDropdown,
  StyledLabel,
  SubSectionTitle,
} from './styles';
import DesignColor from './DesignColor';
import DesignFont from './DesignFont';

interface ICustomizeThemeProps {
  theme: ITheme;
}

const CustomizeTheme: React.FC<ICustomizeThemeProps> = ({ theme }) => {
  const pendingForkedThemeDoc = useSelector(getPendingThemesDoc);
  const forkedTheme = useSelector(getForkedTheme);
  const dispatch = useDispatch();
  const fonts = useFonts();
  const [editableTheme, setEditableTheme] = useState<ITheme>(theme);

  useEffect(() => {
    // return to original state if discards pending changes
    if (!pendingForkedThemeDoc && forkedTheme) {
      setEditableTheme(forkedTheme);
    }
  }, [pendingForkedThemeDoc]);

  useEffect(() => {
    return () => {
      dispatch(setDraftTheme(null));
      dispatch(setForkedTheme({ theme: null }));
    };
  }, []);

  const { body, headline } = useMemo(() => {

    const bodyFont = fonts.find(result => result.value === editableTheme.typography.body);
    const headlineFont = fonts.find(result => result.value === editableTheme.typography.headline);

    return {
      body: bodyFont,
      headline: headlineFont,
    };
  }, [fonts, editableTheme.typography]);

  const handleChangeThemeName = (name: string) => {
    setEditableTheme(editable => ({
      ...editable,
      name,
    }));
  };

  const handleBlurThemeName = () => {
    if (editableTheme.name === theme.name) {
      return;
    }
    handleSetPendingTheme();
  };

  const handleSetPendingTheme = (targetTheme?: ITheme) => {
    const newTheme = {
      ...editableTheme,
      ...targetTheme,
    };
    dispatch(setPendingThemesDoc(editableTheme._id!, newTheme));
    dispatch(setDraftTheme(newTheme));
  };

  const handleChangeAccentColors = (accentPrimary: string) => {
    const accentColors = createAccentColors(accentPrimary);
    const newTheme = {
      ...editableTheme,
      colors: {
        ...editableTheme.colors,
        ...accentColors,
        accentPrimary,
      },
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  const handleChangeHighlightColors = (highlightPrimary: string) => {
    const highlightColors = createHighlightColors(highlightPrimary);
    const newTheme = {
      ...editableTheme,
      colors: {
        ...editableTheme.colors,
        ...highlightColors,
        highlightPrimary,
      },
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  const handleChangeSurfaceColors = (surface1: string) => {
    const surfaceColors = createSurfaceColors(surface1);
    const textColors = createTextColors(surface1);
    const newTheme = {
      ...editableTheme,
      colors: {
        ...editableTheme.colors,
        ...surfaceColors,
        ...textColors,
        surface1,
      },
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  const handleChangeHeadlineFont = (font: IFonts) => {
    if (editableTheme.typography.headline === font.value) {
      return;
    }
    const newTheme = {
      ...editableTheme,
      typography: {
        ...editableTheme.typography,
        headline: font.value,
      },
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  const handleChangeBodyFont = (font: IFonts) => {
    if (editableTheme.typography.body === font.value) {
      return;
    }
    const newTheme = {
      ...editableTheme,
      typography: {
        ...editableTheme.typography,
        body: font.value,
      },
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  const handleClearMobileBackground = () => {
    const newTheme: ITheme = {
      ...editableTheme,
      mobileBackground: undefined,
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  const handleClearDesktopBackground = () => {
    const newTheme: ITheme = {
      ...editableTheme,
      desktopBackground: undefined,
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  const handleSubmitDesktopBackground = (desktopBackground: string) => {
    const newTheme: ITheme = {
      ...editableTheme,
      desktopBackground,
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  const handleSubmitMobileBackground = (mobileBackground: string) => {
    const newTheme: ITheme = {
      ...editableTheme,
      mobileBackground,
    };
    setEditableTheme(newTheme);
    handleSetPendingTheme(newTheme);
  };

  return (
    <>
      <TranslatedText component={SubSectionTitle} stringKey="ADMIN_THEMES_CUSTOMIZE_THEME" />
      <TranslatedText
        component={StyledTextBlock}
        componentProps={{ compact: true }}
        stringKey="ADMIN_DESIGN_TAB_CUSTOMIZE_THEME_DESCRIPTION"
      />
      <ActiveThemeCard theme={editableTheme.colors} name={editableTheme.name} hideFooter={true} type={editableTheme.type} />
      <Input
        labelKey="ADMIN_DESIGN_TAB_CUSTOMIZE_THEME_TITLE"
        value={editableTheme.name}
        onChange={handleChangeThemeName}
        onBlur={handleBlurThemeName}
      />
      <Divider />
      <StyledContentDropdown titleKey="ADMIN_DESIGN_TAB_COLORS">
        <StyledLabel labelKey="ADMIN_DESIGN_TAB_ACCENTS" />
        <DesignColor
          labelKey="ADMIN_DESIGN_TAB_ACCENT_COLOR"
          tooltipKey="ADMIN_DESIGN_TAB_ACCENT_COLOR_TOOLTIP"
          color={editableTheme.colors.accentPrimary}
          onChange={handleChangeAccentColors}
          colorButtonDataTestId="accentColor"
        />
        <DesignColor
          labelKey="ADMIN_DESIGN_TAB_HIGHLIGHT_COLOR"
          tooltipKey="ADMIN_DESIGN_TAB_HIGHLIGHT_COLOR_TOOLTIP"
          color={editableTheme.colors.highlightPrimary}
          onChange={handleChangeHighlightColors}
          colorButtonDataTestId="highlightColor"
        />
        <StyledLabel labelKey="ADMIN_DESIGN_TAB_SURFACES" />
        <DesignColor
          labelKey="ADMIN_DESIGN_TAB_SURFACE_TONE"
          tooltipKey="ADMIN_DESIGN_TAB_SURFACE_TONE_TOOLTIP"
          color={editableTheme.colors.surface1}
          onChange={handleChangeSurfaceColors}
          colorButtonDataTestId="surfaceTone"
        />
      </StyledContentDropdown>
      <Divider />
      <StyledContentDropdown titleKey="ADMIN_DESIGN_TAB_FONTS" defaultCollapsed={true}>
        <DesignFont
          labelKey="ADMIN_DESIGN_TAB_HEADLINE_FONT"
          onChange={handleChangeHeadlineFont}
          options={fonts}
          value={headline}
          searchPlaceholderKey="ADMIN_DESIGN_TAB_SELECT_FONT_PLACEHOLDER"
          tooltipKey="ADMIN_DESIGN_TAB_HEADLINE_FONT_TOOLTIP"
        />
        <DesignFont
          labelKey="ADMIN_DESIGN_TAB_BODY_FONT"
          onChange={handleChangeBodyFont}
          options={fonts}
          value={body}
          searchPlaceholderKey="ADMIN_DESIGN_TAB_SELECT_FONT_PLACEHOLDER"
          tooltipKey="ADMIN_DESIGN_TAB_BODY_FONT_TOOLTIP"
        />
      </StyledContentDropdown>
      <Divider />
      <StyledContentDropdown titleKey="ADMIN_DESIGN_TAB_BACKGROUND" defaultCollapsed={true} marginBottom={120}>
        <DesktopBackgroundUploadZone
          imagePreview={editableTheme.desktopBackground}
          labelKey="ADMIN_DESIGN_TAB_DESKTOP_BACKGROUND"
          onClearImage={handleClearDesktopBackground}
          onFileSubmit={handleSubmitDesktopBackground}
        />
        <MobileBackgroundUploadZone
          imagePreview={editableTheme.mobileBackground}
          labelKey="ADMIN_DESIGN_TAB_MOBILE_BACKGROUND"
          onClearImage={handleClearMobileBackground}
          onFileSubmit={handleSubmitMobileBackground}
        />
      </StyledContentDropdown>
    </>
  );
};

export default CustomizeTheme;
